import React, { useState } from 'react';
import { Button } from './ui/button';
import { Menu, X } from 'lucide-react'; // Import icons

const ResponsiveHeader = ({ view, setView, handleSignOut }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { name: 'Track', value: 'track' },
    { name: 'Create', value: 'create' },
    { name: 'Scoreboard', value: 'scoreboard' },
    { name: 'Subscription', value: 'subscription' },
  ];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleMenuItemClick = (newView) => {
    setView(newView);
    setIsMenuOpen(false);
  };

  return (
    <header className="flex justify-between items-center mb-8">
      <h1 className="text-3xl font-bold text-white">GoalQuest</h1>
      
      {/* Desktop menu */}
      <div className="hidden md:flex space-x-4">
        {menuItems.map((item) => (
          <Button 
            key={item.value}
            variant={view === item.value ? 'default' : 'outline'} 
            onClick={() => setView(item.value)}
          >
            {item.name}
          </Button>
        ))}
        <Button variant="outline" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>

      {/* Mobile menu button */}
      <div className="md:hidden">
        <Button variant="outline" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </Button>
      </div>

      {/* Mobile menu dropdown */}
      {isMenuOpen && (
        <div className="absolute top-16 right-0 w-48 bg-white rounded-md shadow-lg py-1 z-10">
          {menuItems.map((item) => (
            <button
              key={item.value}
              className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${
                view === item.value ? 'bg-gray-100' : ''
              }`}
              onClick={() => handleMenuItemClick(item.value)}
            >
              {item.name}
            </button>
          ))}
          <button
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            onClick={() => {
              handleSignOut();
              setIsMenuOpen(false);
            }}
          >
            Sign Out
          </button>
        </div>
      )}
    </header>
  );
};

export default ResponsiveHeader;