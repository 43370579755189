import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Alert, AlertDescription } from '../components/ui/alert';

function GamepadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="6" x2="10" y1="12" y2="12" />
      <line x1="8" x2="8" y1="10" y2="14" />
      <line x1="15" x2="15.01" y1="13" y2="13" />
      <line x1="18" x2="18.01" y1="11" y2="11" />
      <rect width="20" height="12" x="2" y="6" rx="2" />
    </svg>
  );
}

const SignIn = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [inviteCode, setInviteCode] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('inviteCode');
    if (code) setInviteCode(code);
  }, [location]);

  const checkSubscription = async (userId) => {
    const subscriptionsRef = collection(firestore, 'customers', userId, 'subscriptions');
    const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
    const subscriptionsSnap = await getDocs(q);
    return !subscriptionsSnap.empty;
  };

  const processInvitation = async (user, inviteCode) => {
    try {
      const inviterRef = collection(firestore, 'users');
      const inviterQuery = query(inviterRef, where('inviteCode', '==', inviteCode));
      const inviterSnapshot = await getDocs(inviterQuery);

      if (!inviterSnapshot.empty) {
        const inviterId = inviterSnapshot.docs[0].id;
        const userRef = doc(firestore, 'users', user.uid);
        const inviterRef = doc(firestore, 'users', inviterId);

        await updateDoc(userRef, {
          friends: arrayUnion(inviterId)
        });

        await updateDoc(inviterRef, {
          friends: arrayUnion(user.uid)
        });

        console.log('Friend relationship established');
      }
    } catch (error) {
      console.error('Error processing invitation:', error);
      setError('Error processing invitation. Please try again or contact support.');
    }
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    setError('');

    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await setDoc(doc(firestore, 'customers', userCredential.user.uid), {
          email: userCredential.user.email,
          created: new Date(),
        });
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }

      if (inviteCode) {
        await processInvitation(userCredential.user, inviteCode);
      }

      const hasSubscription = await checkSubscription(userCredential.user.uid);
      if (hasSubscription) {
        navigate('/dashboard');
      } else {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      const userDocRef = doc(firestore, 'customers', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          created: new Date(),
        });
      }

      if (inviteCode) {
        await processInvitation(user, inviteCode);
      }

      const hasSubscription = await checkSubscription(user.uid);
      if (hasSubscription) {
        navigate('/dashboard');
      } else {
        navigate('/subscription');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <div className="flex items-center justify-center mb-6">
          <GamepadIcon className="h-10 w-10 text-primary" />
          <span className="ml-2 text-2xl font-bold text-primary">GoalQuest</span>
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center">
          {isSignUp ? 'Join GoalQuest' : 'Welcome Back'}
        </h2>
        {inviteCode && (
          <p className="text-sm text-center mb-4 text-green-600">
            You've been invited to join GoalQuest!
          </p>
        )}
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleAuth} className="space-y-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1"
            />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1"
            />
          </div>
          <Button type="submit" className="w-full">
            {isSignUp ? 'Sign Up' : 'Sign In'} with Email
          </Button>
        </form>
        <div className="mt-4">
          <Button onClick={handleGoogleSignIn} variant="outline" className="w-full">
            Sign in with Google
          </Button>
        </div>
        <p className="mt-4 text-center text-sm text-gray-600">
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="ml-1 text-primary hover:text-primary/90"
          >
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
        <p className="mt-4 text-center text-xs text-gray-500">
          By signing up, you agree to our{' '}
          <Link to="/terms" className="text-primary hover:underline">Terms of Service</Link>
          {' and '}
          <Link to="/privacy" className="text-primary hover:underline">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
