import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Brain, Heart, DollarSign } from 'lucide-react';
import moment from 'moment';
import TaskBlogPost from './TaskBlogPost';

const CategoryIcon = ({ category }) => {
  switch (category) {
    case 'mind':
      return <Brain className="w-5 h-5 text-blue-500" />;
    case 'body':
      return <Heart className="w-5 h-5 text-red-500" />;
    case 'finances':
      return <DollarSign className="w-5 h-5 text-green-500" />;
    default:
      return null;
  }
};

const ScoreboardView = React.memo(({ 
  users, 
  tasks, 
  currentUserId, 
  blogPosts, 
  onSubmitPost, 
  onEditPost, 
  onDeletePost
}) => {
  const [period, setPeriod] = useState('week');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (users && tasks) {
      setIsLoading(false);
      setError(null);
    } else {
      setError('No data available for the scoreboard.');
      setIsLoading(false);
    }
  }, [users, tasks]);

  const leaderboard = useMemo(() => {
    if (!users || !tasks) {
      return [];
    }

    const now = moment();
    const startDate = moment(now).subtract(1, period);

    return users
      .map((user) => {
        const userTasks = tasks.filter((task) => task.userId === user.uid);
        const categoryCounts = {
          mind: 0,
          body: 0,
          finances: 0,
        };

        userTasks.forEach((task) => {
          Object.entries(task.completedDates || {}).forEach(([date, completed]) => {
            const completionDate = moment(date);
            if (completed && completionDate.isBetween(startDate, now, null, '[]')) {
              categoryCounts[task.category] = (categoryCounts[task.category] || 0) + 1;
            }
          });
        });

        const totalScore = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);

        return {
          userId: user.uid,
          username: user.uid === currentUserId ? 'You' : user.displayName || 'Anonymous',
          ...categoryCounts,
          totalScore,
          isCurrentUser: user.uid === currentUserId,
        };
      })
      .sort((a, b) => b.totalScore - a.totalScore);
  }, [users, tasks, period, currentUserId]);

  if (isLoading) {
    return <div>Loading scoreboard data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Card className="mt-4">
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle className="text-2xl font-bold">Scoreboard</CardTitle>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Filter:</span>
              <Select value={period} onValueChange={setPeriod}>
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Select period" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="week">Last Week</SelectItem>
                  <SelectItem value="month">Last Month</SelectItem>
                  <SelectItem value="year">Last Year</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {leaderboard.length > 0 ? (
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[60px]">Rank</TableHead>
                    <TableHead className="w-[200px] text-left">User</TableHead>
                    <TableHead className="w-[80px] text-right">Mind</TableHead>
                    <TableHead className="w-[80px] text-right">Body</TableHead>
                    <TableHead className="w-[80px] text-right">Finances</TableHead>
                    <TableHead className="w-[80px] text-right">Total</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {leaderboard.map((score, index) => (
                    <TableRow key={score.userId} className={score.isCurrentUser ? 'bg-blue-100' : ''}>
                      <TableCell className="font-medium text-center">{index + 1}</TableCell>
                      <TableCell className="text-left">{score.username}</TableCell>
                      <TableCell className="text-right">
                        <div className="flex items-center justify-end space-x-1">
                          <CategoryIcon category="mind" />
                          <span>{score.mind}</span>
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex items-center justify-end space-x-1">
                          <CategoryIcon category="body" />
                          <span>{score.body}</span>
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex items-center justify-end space-x-1">
                          <CategoryIcon category="finances" />
                          <span>{score.finances}</span>
                        </div>
                      </TableCell>
                      <TableCell className="text-right font-bold">{score.totalScore}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="text-center py-4">
              <p>No data available for the scoreboard.</p>
              <p className="mt-2">Make sure you have completed tasks and friends on your list.</p>
            </div>
          )}
        </CardContent>
      </Card>

      <TaskBlogPost
        posts={blogPosts.map(post => ({
          ...post,
          user: users.find(u => u.uid === post.userId) || { displayName: 'Anonymous', photoURL: null }
        }))}
        onSubmit={onSubmitPost}
        onEdit={onEditPost}
        onDelete={onDeletePost}
        currentUser={users.find((user) => user.uid === currentUserId)}
      />
    </>
  );
});

export default ScoreboardView;
