import React from 'react';
import { Brain, Heart, DollarSign } from 'lucide-react';
import ProfileSection from './ProfileSection';
import XPProgress from './XPProgress';
import CategoryProgress from './CategoryProgress';
import CalendarView from './CalendarView';

const TrackView = ({ 
  user, 
  level, 
  totalXP, 
  xpForNextLevel, 
  xpProgress, 
  categoryProgress, 
  updateUserProfile,
  tasks,
  onCompleteTask,
  onRemoveTask 
}) => {
  return (
    <div className="space-y-6">
      <ProfileSection user={user} updateUserProfile={updateUserProfile} />
      
      <XPProgress
        level={level}
        totalXP={totalXP}
        xpForNextLevel={xpForNextLevel}
        xpProgress={xpProgress}
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <CategoryProgress
          title="Mind Progress"
          icon={<Brain className="w-5 h-5 mr-2 text-blue-500" />}
          progress={categoryProgress.mind}
        />
        <CategoryProgress
          title="Body Progress"
          icon={<Heart className="w-5 h-5 mr-2 text-red-500" />}
          progress={categoryProgress.body}
        />
        <CategoryProgress
          title="Finances Progress"
          icon={<DollarSign className="w-5 h-5 mr-2 text-green-500" />}
          progress={categoryProgress.finances}
        />
      </div>

      <CalendarView tasks={tasks} onCompleteTask={onCompleteTask} onRemoveTask={onRemoveTask} />
    </div>
  );
};

export default TrackView;