import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { doc, getDoc, collection, addDoc, onSnapshot, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { signOut } from 'firebase/auth';
import { Button } from '../components/ui/button';
import { Loader2 } from 'lucide-react';

const stripePromise = loadStripe('pk_live_51PuvgTA61az0DwkG2bY6vBpq4zsW5oM4YutIZGbVPMxLq0RyHIQvDmmDcm91irtpnvNBq9EXxvNXuOF0D90HGPCB00pq49eJuq');

function GamepadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="6" x2="10" y1="12" y2="12" />
      <line x1="8" x2="8" y1="10" y2="14" />
      <line x1="15" x2="15.01" y1="13" y2="13" />
      <line x1="18" x2="18.01" y1="11" y2="11" />
      <rect width="20" height="12" x="2" y="6" rx="2" />
    </svg>
  );
}

const Subscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromInvite, setFromInvite] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const updateUserSubscription = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(firestore, 'users', user.uid);
        await setDoc(userRef, {
          hasActiveSubscription: true
        }, { merge: true });

        const customerRef = doc(firestore, 'customers', user.uid);
        await setDoc(customerRef, {
          hasActiveSubscription: true
        }, { merge: true });

        console.log("Subscription updated successfully");
        setSubscription(true);
        navigate('/dashboard', { replace: true });
      } catch (error) {
        console.error("Error updating user subscription:", error);
        setError("An error occurred while updating your subscription. Please contact support.");
      }
    } else {
      console.error("No user found when updating subscription");
      navigate('/signin');
    }
  }, [navigate]);

  useEffect(() => {
    const checkSubscription = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDocRef = doc(firestore, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (userData.hasActiveSubscription) {
              setSubscription(true);
              navigate('/dashboard');
              return;
            }
            // Check if user came from an invite
            setFromInvite(!!userData.invitedBy);
          } else {
            await setDoc(userDocRef, {
              email: user.email,
              createdAt: new Date(),
              hasActiveSubscription: false
            });
            console.log("Created new user document");
          }

          const customerDocRef = doc(firestore, 'customers', user.uid);
          const customerDocSnap = await getDoc(customerDocRef);

          if (customerDocSnap.exists()) {
            const subscriptionsRef = collection(firestore, 'customers', user.uid, 'subscriptions');
            const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
            const subscriptionsSnap = await getDocs(q);

            if (!subscriptionsSnap.empty) {
              await updateDoc(userDocRef, { hasActiveSubscription: true });
              console.log("Updated user document with subscription status");
              setSubscription(true);
              navigate('/dashboard');
            }
          }
        } catch (err) {
          console.error("Error checking subscription:", err);
          setError("An error occurred while checking your subscription. Please try again.");
        }
      } else {
        navigate('/signin');
      }
      setLoading(false);
    };

    const urlParams = new URLSearchParams(location.search);
    const paymentStatus = urlParams.get('payment_status');

    if (paymentStatus === 'succeeded') {
      updateUserSubscription();
    } else {
      checkSubscription();
    }
  }, [navigate, location, updateUserSubscription]);

  const handleSubscribe = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const docRef = await addDoc(collection(firestore, 'customers', user.uid, 'checkout_sessions'), {
        price: 'price_1PuvpkA61az0DwkGrFMDmWtM',
        success_url: `${window.location.origin}/subscription?payment_status=succeeded`,
        cancel_url: `${window.location.origin}/subscription`,
      });

      onSnapshot(docRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          setError(`An error occurred: ${error.message}`);
        }
        if (sessionId) {
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            setError(`An error occurred: ${error.message}`);
          }
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setError(`An error occurred: ${error.message}`);
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => navigate('/')).catch(console.error);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md w-96 text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-700 mb-4">{error}</p>
          <Button onClick={() => window.location.reload()} className="w-full">
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  if (subscription) {
    navigate('/dashboard');
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <div className="flex items-center justify-center mb-6">
          <GamepadIcon className="h-10 w-10 text-primary" />
          <span className="ml-2 text-2xl font-bold text-primary">GoalQuest</span>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center">Upgrade to Premium</h2>
        {fromInvite && (
          <p className="text-sm text-center mb-4 text-green-600">
            Welcome! You've been invited to join GoalQuest.
          </p>
        )}
        <p className="text-gray-600 mb-6 text-center">
          Unlock all features and supercharge your personal growth journey!
        </p>
        <ul className="mb-6 space-y-2">
          <li className="flex items-center">
            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            Exclusive Progress Insights
          </li>
          <li className="flex items-center">
            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            Unlimited Goal Setting
          </li>
          <li className="flex items-center">
            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M5 13l4 4L19 7"></path>
            </svg>
            Invite and Compete with Friends
          </li>
        </ul>
        <Button onClick={handleSubscribe} className="w-full mb-4">
          Subscribe Now
        </Button>
        <Button onClick={handleLogout} variant="outline" className="w-full">
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Subscription;