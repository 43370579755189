import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { Input } from './ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Brain, Heart, DollarSign, Edit, Trash2, Image, Youtube, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './ui/dialog';

const CategoryIcon = ({ category }) => {
  switch (category) {
    case 'mind':
      return <Brain className="w-5 h-5 text-blue-500" />;
    case 'body':
      return <Heart className="w-5 h-5 text-red-500" />;
    case 'finances':
      return <DollarSign className="w-5 h-5 text-green-500" />;
    default:
      return null;
  }
};

const YouTubeThumbnail = ({ url }) => {
  const videoId = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/)[1];
  return (
    <a 
      href={`https://www.youtube.com/watch?v=${videoId}`} 
      target="_blank" 
      rel="noopener noreferrer"
      className="relative block w-full pt-[56.25%] mt-2 group"
    >
      <img
        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
        alt="YouTube thumbnail"
        className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300">
        <Youtube className="w-16 h-16 text-white opacity-80 group-hover:opacity-100 transition-opacity duration-300" />
      </div>
    </a>
  );
};

const TaskBlogPost = ({ posts, onSubmit, onEdit, onDelete, currentUser }) => {
  const [newPost, setNewPost] = useState({ category: '', content: '', mediaUrl: '' });
  const [editingPost, setEditingPost] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPost.category && newPost.content.trim()) {
      onSubmit({
        ...newPost,
        userId: currentUser.uid,
        username: currentUser.displayName,
        createdAt: new Date().toISOString(),
      });
      setNewPost({ category: '', content: '', mediaUrl: '' });
      setPreviewImage(null);
    }
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setIsEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
    if (editingPost.category && editingPost.content.trim()) {
      onEdit(editingPost);
      setIsEditDialogOpen(false);
      setEditingPost(null);
    }
  };

  const handleDelete = (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      onDelete(postId);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setNewPost({ ...newPost, mediaUrl: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const isYouTubeUrl = (url) => {
    return url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/);
  };

  return (
    <div className="mt-8">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Share Your Progress</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Select
              value={newPost.category}
              onValueChange={(value) => setNewPost({ ...newPost, category: value })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="mind">Mind</SelectItem>
                <SelectItem value="body">Body</SelectItem>
                <SelectItem value="finances">Finances</SelectItem>
              </SelectContent>
            </Select>
            <Textarea
              placeholder="Write about your progress..."
              value={newPost.content}
              onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
              rows={4}
            />
            <div className="flex space-x-2">
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
              >
                <Image className="w-5 h-5 mr-2" />
                Add Image
              </label>
              <Input
                type="text"
                placeholder="Or paste a YouTube URL"
                value={newPost.mediaUrl}
                onChange={(e) => setNewPost({ ...newPost, mediaUrl: e.target.value })}
                className="flex-grow"
              />
            </div>
            {previewImage && (
              <div className="relative w-full pt-[56.25%]">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
                />
              </div>
            )}
            {isYouTubeUrl(newPost.mediaUrl) && <YouTubeThumbnail url={newPost.mediaUrl} />}
            <Button type="submit">Post</Button>
          </form>
        </CardContent>
      </Card>

      <div className="space-y-4">
        {posts.map((post) => (
          <Card key={post.id} className="overflow-hidden">
            <CardHeader className="bg-gray-100">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <CategoryIcon category={post.category} />
                  <CardTitle className="text-lg capitalize">{post.category}</CardTitle>
                </div>
              </div>
            </CardHeader>
            <CardContent className="pt-4">
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-grow md:w-2/3">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center space-x-4">
                      <Avatar>
                        <AvatarImage src={post.user.photoURL} />
                        <AvatarFallback>{post.user.displayName[0]}</AvatarFallback>
                      </Avatar>
                      <div>
                        <p className="text-sm font-semibold">{post.user.displayName}</p>
                        <p className="text-xs text-gray-500 flex items-center">
                          <Calendar className="w-3 h-3 mr-1" />
                          {format(new Date(post.createdAt), 'PPP')}
                        </p>
                      </div>
                    </div>
                    {currentUser.uid === post.userId && (
                      <div className="space-x-2">
                        <Button size="sm" variant="outline" onClick={() => handleEdit(post)}>
                          <Edit className="w-4 h-4 mr-1" /> Edit
                        </Button>
                        <Button size="sm" variant="destructive" onClick={() => handleDelete(post.id)}>
                          <Trash2 className="w-4 h-4 mr-1" /> Delete
                        </Button>
                      </div>
                    )}
                  </div>
                  <p className="mb-4">{post.content}</p>
                </div>
                {post.mediaUrl && (
                  <div className="md:w-1/3">
                    {isYouTubeUrl(post.mediaUrl) ? (
                      <YouTubeThumbnail url={post.mediaUrl} />
                    ) : (
                      <div className="relative w-full pt-[56.25%]">
                        <img
                          src={post.mediaUrl}
                          alt="Post media"
                          className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Post</DialogTitle>
          </DialogHeader>
          <Select
            value={editingPost?.category}
            onValueChange={(value) => setEditingPost({ ...editingPost, category: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="mind">Mind</SelectItem>
              <SelectItem value="body">Body</SelectItem>
              <SelectItem value="finances">Finances</SelectItem>
            </SelectContent>
          </Select>
          <Textarea
            value={editingPost?.content}
            onChange={(e) => setEditingPost({ ...editingPost, content: e.target.value })}
            rows={4}
          />
          <Input
            type="text"
            placeholder="Image URL or YouTube URL"
            value={editingPost?.mediaUrl}
            onChange={(e) => setEditingPost({ ...editingPost, mediaUrl: e.target.value })}
          />
          {editingPost?.mediaUrl && (
            isYouTubeUrl(editingPost.mediaUrl) ? (
              <YouTubeThumbnail url={editingPost.mediaUrl} />
            ) : (
              <div className="relative w-full pt-[56.25%]">
                <img
                  src={editingPost.mediaUrl}
                  alt="Post media"
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
                />
              </div>
            )
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveEdit}>Save Changes</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TaskBlogPost;