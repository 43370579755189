const getWeeksInMonth = (year, month) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  return Math.ceil((lastDay.getDate() - firstDay.getDate() + 1) / 7);
};

const calculateCategoryProgress = (tasks, currentDate) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const weeksInMonth = getWeeksInMonth(year, month);
  let totalTasks = 0;
  let completedTasks = 0;

  console.log(`Processing ${tasks.length} tasks for date: ${currentDate}`);

  tasks.forEach((task, index) => {
    if (!task || !task.frequency || !task.category) {
      console.warn(`Skipping invalid task at index ${index}:`, task);
      return;
    }

    const { frequency, name, category } = task;
    const completedDates = task.completedDates || {};

    console.log(`Task ${index}: ${name || 'Unnamed'} (${category}) - Frequency: ${frequency}`);
    console.log('Completed Dates:', completedDates);

    switch (frequency.toLowerCase()) {
      case 'daily':
        totalTasks += daysInMonth;
        completedTasks += Object.keys(completedDates).filter(date => 
          new Date(date).getMonth() === month && 
          new Date(date).getFullYear() === year
        ).length;
        break;
      case 'weekly':
        totalTasks += weeksInMonth;
        completedTasks += Math.min(weeksInMonth, Object.keys(completedDates).filter(date => 
          new Date(date).getMonth() === month && 
          new Date(date).getFullYear() === year
        ).length);
        break;
      case 'monthly':
        totalTasks += 1;
        completedTasks += Object.keys(completedDates).some(date => 
          new Date(date).getMonth() === month && 
          new Date(date).getFullYear() === year
        ) ? 1 : 0;
        break;
      default:
        console.warn(`Unknown frequency "${frequency}" for task:`, name);
        totalTasks += 1;
        completedTasks += Object.keys(completedDates).some(date => 
          new Date(date).getMonth() === month && 
          new Date(date).getFullYear() === year
        ) ? 1 : 0;
    }

    console.log(`After task ${index}: Total Tasks: ${totalTasks}, Completed Tasks: ${completedTasks}`);
  });

  const progress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
  console.log(`Final Progress: ${progress.toFixed(2)}% (${completedTasks}/${totalTasks})`);
  return progress;
};

const calculateAllProgress = (tasks, currentDate) => {
  const categories = ['mind', 'body', 'finances'];
  const progress = {};

  categories.forEach(category => {
    console.log(`\nCalculating progress for category: ${category}`);
    const categoryTasks = tasks.filter(task => task && task.category && task.category.toLowerCase() === category);
    console.log(`Found ${categoryTasks.length} tasks for ${category}`);
    progress[category] = calculateCategoryProgress(categoryTasks, currentDate);
  });

  console.log('\nFinal progress for all categories:', progress);
  return progress;
};

export { calculateAllProgress };