import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Brain, Heart, DollarSign, HelpCircle, Trash2 } from 'lucide-react';

const CategoryIcon = React.memo(({ category }) => {
  switch (category) {
    case 'mind':
      return <Brain className="w-5 h-5 mr-2 text-blue-500" />;
    case 'body':
      return <Heart className="w-5 h-5 mr-2 text-red-500" />;
    case 'finances':
      return <DollarSign className="w-5 h-5 mr-2 text-green-500" />;
    default:
      return <HelpCircle className="w-5 h-5 mr-2 text-gray-500" />;
  }
});

const CalendarView = ({ tasks, onCompleteTask, onRemoveTask }) => {
  const [currentDate, setCurrentDate] = useState(() => moment());
  const [selectedDate, setSelectedDate] = useState(() => moment());

  const displayedTasks = useMemo(() => {
    const date = selectedDate.format('YYYY-MM-DD');
    return tasks
      .filter(task => !task.removed)
      .map(task => ({
        ...task,
        isCompleted: task.completedDates && task.completedDates[date] === true
      }));
  }, [selectedDate, tasks]);

  const handleDateSelect = useCallback((date) => {
    setSelectedDate(date);
  }, []);
  
  const handlePrevMonth = useCallback(() => setCurrentDate(prev => prev.clone().subtract(1, 'month')), []);
  const handleNextMonth = useCallback(() => setCurrentDate(prev => prev.clone().add(1, 'month')), []);

  const renderCalendar = useCallback(() => {
    const startOfMonth = currentDate.clone().startOf('month');
    const endOfMonth = currentDate.clone().endOf('month');
    const startDate = startOfMonth.clone().startOf('week');
    const endDate = endOfMonth.clone().endOf('week');

    let day = startDate.clone();
    const calendar = [];

    while (day.isBefore(endDate)) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        const clonedDay = day.clone();
        const formattedDate = clonedDay.format('YYYY-MM-DD');
        const isCurrentMonth = clonedDay.month() === currentDate.month();
        const hasGoals = tasks.some(task => task.completedDates && task.completedDates[formattedDate]);

        week.push(
          <td 
            key={clonedDay.format('YYYY-MM-DD')} 
            className={`p-2 text-center cursor-pointer hover:bg-gray-100 
              ${!isCurrentMonth ? 'text-gray-400' : ''} 
              ${hasGoals ? 'font-bold' : ''}
              ${selectedDate.isSame(clonedDay, 'day') ? 'bg-blue-200' : ''}`}
            onClick={() => handleDateSelect(clonedDay)}
          >
            {clonedDay.date()}
          </td>
        );
        day.add(1, 'day');
      }
      calendar.push(<tr key={day.week()}>{week}</tr>);
    }

    return calendar;
  }, [currentDate, selectedDate, tasks, handleDateSelect]);

  const renderTasks = useMemo(() => {
    const tasksByCategory = displayedTasks.reduce((acc, task) => {
      const category = (task.category || 'other').toLowerCase();
      if (!acc[category]) acc[category] = [];
      acc[category].push(task);
      return acc;
    }, {});

    return (
      <div className="mt-6 space-y-6">
        {Object.entries(tasksByCategory).map(([category, categoryTasks]) => (
          <Card key={category}>
            <CardHeader>
              <CardTitle className="text-lg font-semibold flex items-center">
                <CategoryIcon category={category} />
                {category.charAt(0).toUpperCase() + category.slice(1)} Tasks
              </CardTitle>
            </CardHeader>
            <CardContent>
              {categoryTasks.map((task) => (
                <div key={task.id} className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded">
                  <div className="flex-grow">
                    <span className="font-medium">{task.title}</span>
                    <span className="ml-2 text-sm text-gray-600">({task.xp || 0} XP)</span>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      size="sm"
                      variant={task.isCompleted ? 'default' : 'outline'}
                      onClick={() => onCompleteTask(task.id, selectedDate.toDate())}
                    >
                      {task.isCompleted ? 'Completed' : 'Mark Complete'}
                    </Button>
                    <Button
                      size="sm"
                      variant="destructive"
                      onClick={() => onRemoveTask(task.id)}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }, [displayedTasks, selectedDate, onCompleteTask, onRemoveTask]);

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>GoalQuest Calendar</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between items-center mb-4">
          <Button onClick={handlePrevMonth}>&lt;</Button>
          <h3 className="text-lg font-semibold">{currentDate.format('MMMM YYYY')}</h3>
          <Button onClick={handleNextMonth}>&gt;</Button>
        </div>
        <table className="w-full mb-4">
          <thead>
            <tr>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                <th key={day} className="p-2">{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderCalendar()}
          </tbody>
        </table>
        {renderTasks}
      </CardContent>
    </Card>
  );
};

export default CalendarView;