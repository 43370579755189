import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from './ui/card';
import { Progress } from './ui/progress';
import { Tooltip } from './ui/Tooltip';

const XPProgress = ({ level, totalXP, xpForNextLevel, xpProgress }) => {
  return (
    <Card className="bg-card text-card-foreground">
      <CardHeader>
        <CardTitle>Level Progression</CardTitle>
        <CardDescription>Your journey to greatness</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col items-center justify-center gap-4">
        <div className="text-3xl font-bold">Level {level}</div>
        <Tooltip content={`${xpForNextLevel - totalXP} XP to Level ${level + 1}`}>
          <div className="w-full">
            <Progress value={xpProgress} className="w-full" />
          </div>
        </Tooltip>
        <p className="text-sm text-gray-500">
          {totalXP}/{xpForNextLevel} XP for Level {level + 1}
        </p>
        <p className="text-xs text-gray-400">
          Unlocks new badge and features at next level!
        </p>
      </CardContent>
    </Card>
  );
};

export default XPProgress;