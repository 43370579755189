import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';

export function ProfileSection({ user, updateUserProfile }) {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    displayName: '',
    photoURL: '',
    goals: '',
    rewards: '',
    quote: '',
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (user) {
      setProfileData(prevData => ({
        ...prevData,
        displayName: user.displayName || prevData.displayName,
        photoURL: user.photoURL || prevData.photoURL,
        goals: user.goals || prevData.goals,
        rewards: user.rewards || prevData.rewards,
        quote: user.quote || prevData.quote,
      }));
    }
  }, [user]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setProfileData(prev => ({ ...prev, photoURL: URL.createObjectURL(e.target.files[0]) }));
    }
  };

  const handleSave = async () => {
    try {
      await updateUserProfile(profileData, file);
      setIsEditing(false);
      setFile(null);
    } catch (error) {
      console.error('Error saving profile:', error);
      alert('Failed to save profile. Please try again.');
    }
  };

  if (!user) {
    return <div>Loading profile...</div>;
  }

  return (
    <Card className="w-full bg-card text-card-foreground">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg font-bold">Profile</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center space-x-4">
          <Avatar className="w-24 h-24">
            <AvatarImage src={profileData.photoURL} alt={profileData.displayName || 'User'} />
            <AvatarFallback>{profileData.displayName?.charAt(0) || 'U'}</AvatarFallback>
          </Avatar>
          <div>
            <h3 className="text-xl font-semibold">{profileData.displayName || 'User'}</h3>
            <p className="text-sm text-gray-500">{user.email || 'No email provided'}</p>
          </div>
        </div>

        {isEditing ? (
          <div className="space-y-2">
            <Input 
              type="text" 
              placeholder="Display Name" 
              value={profileData.displayName} 
              onChange={(e) => setProfileData(prev => ({ ...prev, displayName: e.target.value }))}
            />
            <Input type="file" accept="image/*" onChange={handleFileChange} />
            <Textarea 
              placeholder="Goal" 
              value={profileData.goals} 
              onChange={(e) => setProfileData(prev => ({ ...prev, goals: e.target.value }))} 
            />
            <Textarea 
              placeholder="Reward" 
              value={profileData.rewards} 
              onChange={(e) => setProfileData(prev => ({ ...prev, rewards: e.target.value }))} 
            />
            <Input 
              type="text" 
              placeholder="Favorite Motivational Quote" 
              value={profileData.quote} 
              onChange={(e) => setProfileData(prev => ({ ...prev, quote: e.target.value }))} 
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsEditing(false)} variant="outline">Cancel</Button>
              <Button onClick={handleSave}>Save</Button>
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            <div>
              <h4 className="font-semibold">Goal:</h4>
              <p>{profileData.goals || 'Not specified'}</p>
            </div>
            <div>
              <h4 className="font-semibold">Reward:</h4>
              <p>{profileData.rewards || 'Not specified'}</p>
            </div>
            <div>
              <h4 className="font-semibold">Favorite Quote:</h4>
              <p>{profileData.quote || 'Not specified'}</p>
            </div>
            <Button 
              onClick={() => setIsEditing(true)} 
              className="px-4 py-2 text-sm inline-block mx-auto mt-2"
            >
              Edit Profile
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default ProfileSection;