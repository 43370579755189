import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Progress } from './ui/progress';

const CategoryProgress = ({ title, icon, progress }) => {
  return (
    <Card className="bg-white/90 backdrop-blur-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <CardHeader>
        <CardTitle className="flex items-center">
          {icon}
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <div className="text-4xl font-bold mb-2">{Math.round(progress)}%</div>
        <Progress value={progress} className="w-full" />
      </CardContent>
    </Card>
  );
};

export default CategoryProgress;