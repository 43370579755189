import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAXZWRV2w1q3WExTyFIC6y1ch8ktAYwG9Y",
  authDomain: "life-rpg-5e253.firebaseapp.com",
  databaseURL: "https://life-rpg-5e253-default-rtdb.firebaseio.com",
  projectId: "life-rpg-5e253",
  storageBucket: "life-rpg-5e253.appspot.com",
  messagingSenderId: "584344326942",
  appId: "1:584344326942:web:37c68ec84d9b3b80d02f0f",
  measurementId: "G-GGJZCWE5EE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

console.log('Firebase initialized successfully');

export { auth, firestore, storage };