import React, { useState } from 'react';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Check, Copy } from 'lucide-react';

const InviteLinkSection = ({ inviteLink, onGenerateLink }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Card className="mt-6 bg-white">
      <CardContent className="p-6">
        <h3 className="text-lg font-semibold mb-4">Invite Friends</h3>
        {inviteLink ? (
          <div className="space-y-4">
            <p className="text-sm text-gray-600">Share this link with your friends:</p>
            <div className="flex items-center space-x-2">
              <Input 
                value={inviteLink} 
                readOnly 
                className="flex-grow bg-gray-50"
              />
              <Button 
                onClick={copyToClipboard}
                variant="outline"
                className="flex items-center space-x-1"
              >
                {copied ? (
                  <>
                    <Check className="w-4 h-4" />
                    <span>Copied</span>
                  </>
                ) : (
                  <>
                    <Copy className="w-4 h-4" />
                    <span>Copy</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        ) : (
          <Button 
            onClick={onGenerateLink} 
            size="sm" 
            className="w-auto px-4 py-2 text-sm"
          >
            Generate Invite Link
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default InviteLinkSection;