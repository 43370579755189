import React from 'react';
import { Button } from './ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from './ui/card';
import { Badge } from './ui/badge';
import { AlertCircle } from 'lucide-react';

const SubscriptionManagement = ({ subscriptionStatus, onManageSubscription }) => {
  console.log('onManageSubscription prop:', onManageSubscription);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Card className="w-full max-w-md mx-auto bg-white shadow-xl">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Your Subscription</CardTitle>
        <CardDescription>Manage your GoalQuest Premium subscription</CardDescription>
      </CardHeader>
      <CardContent>
        {subscriptionStatus ? (
          <>
            <div className="flex items-center justify-between mb-4">
              <span className="font-semibold">Status:</span>
              <Badge variant={subscriptionStatus.status === 'active' ? 'default' : 'secondary'}>
                {subscriptionStatus.status === 'active' ? 'Active' : 'Trialing'}
              </Badge>
            </div>
            <div className="flex items-center justify-between mb-4">
              <span className="font-semibold">Renews on:</span>
              <span>{formatDate(subscriptionStatus.currentPeriodEnd)}</span>
            </div>
            {subscriptionStatus.cancel_at_period_end && (
              <div className="text-yellow-600 font-semibold text-center mt-4">
                Your subscription will be cancelled at the end of the current period.
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center text-yellow-600">
            <AlertCircle className="mr-2" />
            <span>No active subscription found</span>
          </div>
        )}
      </CardContent>
      <CardFooter>
        <Button
          onClick={() => {
            console.log('Manage Subscription button clicked');
            onManageSubscription();
          }}
          className="w-full"
        >
          {subscriptionStatus ? 'Manage Subscription' : 'Upgrade to Premium'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SubscriptionManagement;
