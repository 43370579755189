import * as React from "react";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const labelVariants = cva(
  "inline-flex items-center rounded-md px-2 py-0.5 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-gray-100 text-gray-800 hover:bg-gray-200",
        primary: "bg-blue-100 text-blue-800 hover:bg-blue-200",
        secondary: "bg-green-100 text-green-800 hover:bg-green-200",
        destructive: "bg-red-100 text-red-800 hover:bg-red-200",
        outline: "border border-gray-300 text-gray-800",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

function Label({
  className,
  variant,
  ...props
}) {
  return (<span className={cn(labelVariants({ variant }), className)} {...props} />);
}

export { Label, labelVariants };
