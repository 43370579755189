import React from 'react';
import { Link } from 'react-router-dom';
import landingImage from './assets/iphone_view.png';
import ResponsiveLandingHeader from './components/ResponsiveLandingHeader';
import { ArrowRight, CheckCircle } from 'lucide-react';

export default function LandingPage() {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <ResponsiveLandingHeader />

      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-20 lg:py-24">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_800px] lg:gap-12 items-center">
              <div className="flex flex-col justify-center space-y-4">
                <div className="space-y-2">
                  <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl xl:text-6xl/none bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">
                    Achieve Greatness with GoalQuest
                  </h1>
                  <p className="max-w-[600px] text-xl text-gray-600 md:text-2xl">
                    Transform your goals into an engaging, gamified experience. Share progress, compete with friends, and stay motivated!
                  </p>
                </div>
                <div className="flex flex-col gap-3 sm:flex-row">
                  <Link
                    to="/signin"
                    className="inline-flex h-12 items-center justify-center rounded-full bg-primary px-8 text-base font-medium text-white shadow-lg transition-all hover:bg-primary/90 hover:scale-105"
                  >
                    Get Started
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Link>
                  <a
                    href="https://medium.com/@marc.smith_92310/why-i-created-goalquest-the-journey-to-achieving-greatness-254c36141ef6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex h-12 items-center justify-center rounded-full border-2 border-primary bg-white px-8 text-base font-medium text-primary shadow-lg transition-all hover:bg-primary/10 hover:scale-105"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div className="aspect-video w-full overflow-hidden rounded-2xl shadow-2xl lg:order-last">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/1x0UM6OKU0c?si=FU0n968f0WOKjQwv"
                  title="GoalQuest Introduction"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="w-full py-16 md:py-24 lg:py-32 bg-gradient-to-b from-gray-50 to-white">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl">
                Maximize Your Growth with GoalQuest
              </h2>
              <p className="max-w-[900px] text-xl text-gray-600 md:text-2xl">
                GoalQuest offers personalized tools to track progress, share achievements, and compete with friends—all in a motivating environment that rewards your success.
              </p>
            </div>
            <div className="mx-auto grid max-w-6xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-16">
              <div className="lg:order-last">
                <img
                  className="w-full h-auto rounded-2xl object-cover shadow-2xl"
                  src={landingImage}
                  alt="Task Management"
                />
              </div>
              <div className="flex flex-col justify-center space-y-6">
                <FeatureItem
                  title="Progress Tracking"
                  description="Visualize your journey and see how every completed task takes you closer to achieving your personal and professional goals."
                />
                <FeatureItem
                  title="Share Your Achievements"
                  description="Post updates, add images, and videos to celebrate milestones, and engage with a community that pushes you to stay consistent."
                />
                <FeatureItem
                  title="Leaderboards & Friendly Competition"
                  description="Compete with friends on the leaderboard and earn XP across different categories like Mind, Body, and Finances."
                />
                <FeatureItem
                  title="Create Custom Tasks"
                  description="Customize your tasks to fit your lifestyle. Choose the category, difficulty, and frequency to craft a personal roadmap to success."
                />
              </div>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section className="w-full py-16 md:py-24 lg:py-32 bg-primary text-white">
          <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
            <div className="space-y-3">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight lg:text-5xl">
                Ready to Level Up with GoalQuest?
              </h2>
              <p className="max-w-[600px] mx-auto text-xl md:text-2xl text-primary-foreground/80">
                Start your journey today and experience the transformative power of goal tracking, progress sharing, and healthy competition.
              </p>
            </div>
            <div className="flex flex-col items-center gap-3 sm:flex-row justify-center">
              <Link
                to="/signin"
                className="inline-flex h-12 items-center justify-center rounded-full bg-white px-8 text-base font-medium text-primary shadow-lg transition-all hover:bg-gray-100 hover:scale-105"
              >
                Get Started
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <a
                href="https://medium.com/@marc.smith_92310/my-journey-into-monk-mode-how-im-gamifying-self-improvement-3f2a8a707d11"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex h-12 items-center justify-center rounded-full border-2 border-white bg-transparent px-8 text-base font-medium text-white shadow-lg transition-all hover:bg-white/10 hover:scale-105"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="w-full py-6 bg-gray-100">
        <div className="container flex flex-col gap-2 sm:flex-row items-center justify-between px-4 md:px-6">
          <p className="text-sm text-gray-600">&copy; 2024 GoalQuest. All rights reserved.</p>
          <nav className="flex gap-4 sm:gap-6">
            <Link to="/terms" className="text-sm text-gray-600 hover:underline underline-offset-4">
              Terms of Service
            </Link>
            <Link to="/privacy" className="text-sm text-gray-600 hover:underline underline-offset-4">
              Privacy
            </Link>
          </nav>
        </div>
      </footer>
    </div>
  );
}

function FeatureItem({ title, description }) {
  return (
    <div className="flex items-start space-x-4">
      <CheckCircle className="mt-1 h-6 w-6 flex-shrink-0 text-primary" />
      <div>
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="mt-1 text-gray-600">{description}</p>
      </div>
    </div>
  );
}