import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react'; // Import icons

function GamepadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="6" x2="10" y1="12" y2="12" />
      <line x1="8" x2="8" y1="10" y2="14" />
      <line x1="15" x2="15.01" y1="13" y2="13" />
      <line x1="18" x2="18.01" y1="11" y2="11" />
      <rect width="20" height="12" x="2" y="6" rx="2" />
    </svg>
  );
}

const ResponsiveLandingHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const menuItems = [
    { name: 'Features', path: '/features' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'About', path: 'https://medium.com/@marc.smith_92310/my-journey-into-monk-mode-how-im-gamifying-self-improvement-3f2a8a707d11', external: true },
    { name: 'Contact', path: '/contact' },
  ];

  return (
    <header className="px-6 lg:px-8 h-24 flex items-center justify-between bg-gray-50">
      <Link to="/" className="flex items-center">
        <GamepadIcon className="h-16 w-16 text-primary" />
        <span className="ml-3 text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">GoalQuest</span>
      </Link>
      
      {/* Desktop menu */}
      <nav className="hidden md:flex items-center gap-8 sm:gap-10">
        {menuItems.map((item, index) => (
          item.external ? (
            <a 
              key={index}
              href={item.path} 
              className="text-base font-medium hover:text-primary transition-colors duration-200"
              target="_blank" 
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          ) : (
            <Link 
              key={index}
              to={item.path} 
              className="text-base font-medium hover:text-primary transition-colors duration-200"
            >
              {item.name}
            </Link>
          )
        ))}
        <Link
          to="/signin"
          className="inline-flex h-11 items-center justify-center rounded-full bg-primary px-8 text-sm font-medium text-white shadow transition-colors hover:bg-primary/90"
        >
          Sign In / Sign Up
        </Link>
      </nav>

      {/* Mobile menu button */}
      <div className="md:hidden">
        <button 
          onClick={toggleMenu}
          className="text-gray-500 hover:text-primary transition-colors duration-200"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
        </button>
      </div>

      {/* Mobile menu dropdown */}
      {isMenuOpen && (
        <div className="absolute top-24 left-0 right-0 bg-white shadow-lg z-50 md:hidden">
          {menuItems.map((item, index) => (
            item.external ? (
              <a 
                key={index}
                href={item.path} 
                className="block px-6 py-3 text-base font-medium hover:bg-gray-100 transition-colors duration-200"
                target="_blank" 
                rel="noopener noreferrer"
                onClick={toggleMenu}
              >
                {item.name}
              </a>
            ) : (
              <Link 
                key={index}
                to={item.path} 
                className="block px-6 py-3 text-base font-medium hover:bg-gray-100 transition-colors duration-200"
                onClick={toggleMenu}
              >
                {item.name}
              </Link>
            )
          ))}
          <Link
            to="/signin"
            className="block px-6 py-3 text-base font-medium bg-primary text-white hover:bg-primary/90 transition-colors duration-200"
            onClick={toggleMenu}
          >
            Sign In / Sign Up
          </Link>
        </div>
      )}
    </header>
  );
};

export default ResponsiveLandingHeader;