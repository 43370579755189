import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import TrackView from './components/TrackView';
import CreateView from './components/CreateView';
import ScoreboardView from './components/ScoreboardView';
import SubscriptionManagement from './components/SubscriptionManagement';
import { calculateAllProgress } from './components/progressCalculations';
import { auth, firestore, storage } from './firebase';
import InviteLinkSection from './components/InviteLinkSection';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { updateProfile, signOut } from 'firebase/auth';
import ResponsiveHeader from './components/ResponsiveHeader';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  orderBy,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [view, setView] = useState('track');
  const [level, setLevel] = useState(1);
  const [totalXP, setTotalXP] = useState(0);
  const [xpForNextLevel, setXpForNextLevel] = useState(500);
  const [xpProgress, setXpProgress] = useState(0);
  const [categoryProgress, setCategoryProgress] = useState({
    mind: 0,
    body: 0,
    finances: 0,
  });
  const [userTasks, setUserTasks] = useState([]);
  const [friendsTasks, setFriendsTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [inviteLink, setInviteLink] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [cancellationMessage, setCancellationMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cancellationStatus = params.get('cancellation');

    if (cancellationStatus === 'success') {
      setCancellationMessage('Your subscription has been successfully cancelled and will end at the end of the current billing period.');
    } else if (cancellationStatus === 'cancelled') {
      setCancellationMessage('Subscription cancellation was not completed.');
    }
  }, [location]);

  const fetchSubscriptionStatus = useCallback(async () => {
    if (!user) return;

    try {
      const customerRef = doc(firestore, 'customers', user.uid);
      const subscriptionsRef = collection(customerRef, 'subscriptions');
      const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
      const subscriptionsSnapshot = await getDocs(q);

      if (!subscriptionsSnapshot.empty) {
        const subscription = subscriptionsSnapshot.docs[0].data();
        const subscriptionId = subscriptionsSnapshot.docs[0].id;

        setSubscriptionStatus({
          id: subscriptionId,
          status: subscription.status,
          currentPeriodEnd: subscription.current_period_end.toDate(),
          cancel_at_period_end: subscription.cancel_at_period_end,
        });
      } else {
        setSubscriptionStatus(null);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setSubscriptionStatus(null);
    }
  }, [user]);

  const fetchUserProfile = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfileData({
          uid: userId,
          displayName: userData.displayName || user.displayName,
          photoURL: userData.photoURL || user.photoURL,
          goals: userData.goals || '',
          rewards: userData.rewards || '',
          quote: userData.quote || '',
        });
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }, [user]);

  const fetchTasks = useCallback(async () => {
    if (!user) return;
    const tasksQuery = query(collection(firestore, 'tasks'), where('userId', '==', user.uid));
    const tasksSnapshot = await getDocs(tasksQuery);
    const tasksData = tasksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const tasksWithCompletions = tasksData.map((task) => ({
      ...task,
      completedDates: task.completedDates || {},
    }));

    setUserTasks(tasksWithCompletions);
  }, [user]);

  const fetchUserData = useCallback(async (userId) => {
    await fetchUserProfile(userId);
    if (user) {
      await fetchTasks();
    }
  }, [fetchUserProfile, fetchTasks, user]);

  const checkSubscription = useCallback(async (userId) => {
    try {
      const subscriptionsRef = collection(firestore, 'customers', userId, 'subscriptions');
      const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));
      const subscriptionsSnap = await getDocs(q);
      return !subscriptionsSnap.empty;
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const hasSubscription = await checkSubscription(currentUser.uid);
        if (!hasSubscription) {
          navigate('/subscription');
          return;
        }
        setUser(currentUser);
        fetchUserData(currentUser.uid);
        fetchSubscriptionStatus();
      } else {
        setUser(null);
        setProfileData(null);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate, fetchUserData, fetchSubscriptionStatus, checkSubscription]);

  const calculateLevelAndProgress = useCallback((xp) => {
    const levels = [0, 500, 1000, 2000, 3500, 5500, 8000, 11000, 14500, 18500];
    let currentLevel = 1;
    let nextLevelXP = 500;

    for (let i = 1; i < levels.length; i++) {
      if (xp >= levels[i]) {
        currentLevel = i + 1;
      } else {
        nextLevelXP = levels[i];
        break;
      }
    }

    setLevel(currentLevel);
    setXpForNextLevel(nextLevelXP);
    setXpProgress(
      ((xp - levels[currentLevel - 1]) / (nextLevelXP - levels[currentLevel - 1])) * 100
    );
  }, []);

  const fetchAllUsersAndTasks = useCallback(async () => {
    if (!user) return;
    try {
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      let userData = userDoc.data();

      if (!userData || !userData.friends) {
        userData = {
          displayName: user.displayName || '',
          email: user.email || '',
          photoURL: user.photoURL || '',
          friends: [],
        };
        await setDoc(doc(firestore, 'users', user.uid), userData);
      }

      const friendIds = userData.friends || [];

      const usersQuery = query(
        collection(firestore, 'users'),
        where('__name__', 'in', [user.uid, ...friendIds])
      );
      const usersSnapshot = await getDocs(usersQuery);
      const usersData = usersSnapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
      setUsers(usersData);

      if (friendIds.length > 0) {
        const friendTasksQueries = friendIds.map(friendId =>
          query(collection(firestore, 'tasks'), where('userId', '==', friendId))
        );
        const friendTasksSnapshots = await Promise.all(friendTasksQueries.map(q => getDocs(q)));
        const friendTasksData = friendTasksSnapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
        setFriendsTasks(friendTasksData);
      } else {
        setFriendsTasks([]);
      }
    } catch (error) {
      console.error('Error fetching users and tasks:', error);
      setUsers([]);
      setFriendsTasks([]);
    }
  }, [user]);

  const fetchBlogPosts = useCallback(async () => {
    try {
      const postsQuery = query(collection(firestore, 'blogPosts'), orderBy('createdAt', 'desc'));
      const postsSnapshot = await getDocs(postsQuery);
      const postsData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBlogPosts(postsData);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  }, []);

  const redirectToCustomerPortal = useCallback(async () => {
    if (!user) {
      alert('You must be logged in to access the customer portal.');
      return;
    }

    try {
      const functions = getFunctions();
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data } = await createPortalLink({
        returnUrl: window.location.origin + '/dashboard',
        locale: "auto",
      });

      if (data && data.url) {
        window.location.assign(data.url);
      } else {
        throw new Error('Invalid response from createPortalLink.');
      }
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
      alert('Unable to access the customer portal. Please try again later.');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchAllUsersAndTasks();
      fetchBlogPosts();
    }
  }, [user, fetchAllUsersAndTasks, fetchBlogPosts]);

  const memoizedProgress = useMemo(() => {
    if (userTasks.length === 0) return { mind: 0, body: 0, finances: 0 };
    const currentDate = new Date();
    return calculateAllProgress(userTasks, currentDate);
  }, [userTasks]);

  const memoizedXP = useMemo(() => {
    return userTasks.reduce((total, task) => {
      const completedDatesCount = Object.keys(task.completedDates || {}).length;
      return total + (task.xp || 0) * completedDatesCount;
    }, 0);
  }, [userTasks]);

  useEffect(() => {
    console.log('Memoized Progress:', memoizedProgress); // Debugging log
    setCategoryProgress(memoizedProgress);
  }, [memoizedProgress]);

  useEffect(() => {
    console.log('Category Progress:', categoryProgress); // Debugging log
  }, [categoryProgress]);

  useEffect(() => {
    if (memoizedXP !== totalXP) {
      setTotalXP(memoizedXP);
      calculateLevelAndProgress(memoizedXP);
    }
  }, [memoizedXP, totalXP, calculateLevelAndProgress]);

  const handleCreateTask = useCallback(async (taskData) => {
    if (!user) return;

    try {
      const newTaskRef = await addDoc(collection(firestore, 'tasks'), {
        ...taskData,
        category: taskData.category.toLowerCase(), // Ensure consistent casing
        userId: user.uid,
        createdAt: serverTimestamp(),
        completedDates: {},
      });

      const newTask = {
        id: newTaskRef.id,
        ...taskData,
        category: taskData.category.toLowerCase(), // Ensure consistent casing
        userId: user.uid,
        createdAt: new Date(),
        completedDates: {},
      };

      setUserTasks((prevTasks) => [...prevTasks, newTask]);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  }, [user]);

  const updateUserProfile = useCallback(async (newProfileData, file = null) => {
    if (!user) {
      throw new Error('User not logged in');
    }

    try {
      const userRef = doc(firestore, 'users', user.uid);
      let photoURL = newProfileData.photoURL || user.photoURL;

      if (file) {
        const filename = `${uuidv4()}_${file.name}`;
        const storageRef = ref(storage, `profile_pictures/${user.uid}/${filename}`);
        const snapshot = await uploadBytes(storageRef, file);
        photoURL = await getDownloadURL(snapshot.ref);
      }

      const dataToUpdate = {
        displayName: newProfileData.displayName,
        photoURL,
        goals: newProfileData.goals,
        rewards: newProfileData.rewards,
        quote: newProfileData.quote,
        updatedAt: serverTimestamp(),
      };

      await setDoc(userRef, dataToUpdate, { merge: true });

      await updateProfile(user, {
        displayName: newProfileData.displayName,
        photoURL: photoURL,
      });

      setProfileData((prevData) => ({ ...prevData, ...dataToUpdate }));

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
      throw error;
    }
  }, [user]);

  const handleSignOut = useCallback(() => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  }, [navigate]);

  const removeTask = useCallback(async (taskId) => {
    if (!user) return;

    try {
      const taskRef = doc(firestore, 'tasks', taskId);
      await updateDoc(taskRef, {
        removed: true,
        removedAt: serverTimestamp(),
      });

      setUserTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
    } catch (error) {
      console.error('Error removing task:', error);
    }
  }, [user]);

  const handleCompleteTask = useCallback(async (taskId, date) => {
    if (!user) return;

    try {
      const dateString = moment(date).format('YYYY-MM-DD');

      const taskRef = doc(firestore, 'tasks', taskId);
      const taskDoc = await getDoc(taskRef);

      if (!taskDoc.exists()) {
        console.error('Task not found');
        return;
      }

      const taskData = taskDoc.data();
      const updatedCompletedDates = {
        ...taskData.completedDates,
        [dateString]: true,
      };

      await updateDoc(taskRef, {
        completedDates: updatedCompletedDates,
      });

      setUserTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId
            ? { ...task, completedDates: updatedCompletedDates }
            : task
        )
      );

      const newXP = totalXP + (taskData.xp || 0);
      setTotalXP(newXP);
      calculateLevelAndProgress(newXP);

      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, {
        totalXP: newXP,
      });
    } catch (error) {
      console.error('Error completing task:', error);
      fetchTasks();
    }
  }, [user, totalXP, calculateLevelAndProgress, fetchTasks]);

  const generateInviteLink = useCallback(async () => {
    if (!user) return;

    const inviteCode = uuidv4().slice(0, 8);
    const inviteLink = `${window.location.origin}/invite/${inviteCode}`;

    const userRef = doc(firestore, 'users', user.uid);
    await setDoc(userRef, { inviteCode }, { merge: true });

    setInviteLink(inviteLink);
  }, [user]);

  const handleSubmitPost = useCallback(async (newPost) => {
    try {
      const docRef = await addDoc(collection(firestore, 'blogPosts'), newPost);
      const postWithId = { id: docRef.id, ...newPost };
      setBlogPosts((prevPosts) => [postWithId, ...prevPosts]);
    } catch (error) {
      console.error('Error adding blog post:', error);
    }
  }, []);

  const handleEditPost = useCallback(async (editedPost) => {
    try {
      const postRef = doc(firestore, 'blogPosts', editedPost.id);
      await updateDoc(postRef, {
        category: editedPost.category,
        content: editedPost.content,
        updatedAt: serverTimestamp(),
      });
      setBlogPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editedPost.id ? { ...post, ...editedPost } : post
        )
      );
    } catch (error) {
      console.error('Error editing blog post:', error);
    }
  }, []);

  const handleDeletePost = useCallback(async (postId) => {
    try {
      await deleteDoc(doc(firestore, 'blogPosts', postId));
      setBlogPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error('Error deleting blog post:', error);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 p-6">
      <ResponsiveHeader 
        view={view} 
        setView={setView} 
        handleSignOut={handleSignOut}
      />

      <main>
        {cancellationMessage && (
          <div className="mb-4 p-4 bg-white rounded-lg shadow text-center">
            {cancellationMessage}
          </div>
        )}

        {view === 'track' && profileData && (
          <TrackView
            user={profileData}
            level={level}
            totalXP={totalXP}
            xpForNextLevel={xpForNextLevel}
            xpProgress={xpProgress}
            categoryProgress={categoryProgress}
            updateUserProfile={updateUserProfile}
            tasks={userTasks}
            onCompleteTask={handleCompleteTask}
            onRemoveTask={removeTask}
          />
        )}
        {view === 'create' && <CreateView onCreateTask={handleCreateTask} />}
        {view === 'scoreboard' && (
          <>
            <InviteLinkSection
              inviteLink={inviteLink}
              onGenerateLink={generateInviteLink}
            />
            <ScoreboardView 
              users={users} 
              tasks={[...userTasks, ...friendsTasks]} 
              currentUserId={user ? user.uid : null} 
              onSubmitPost={handleSubmitPost}
              onEditPost={handleEditPost}
              onDeletePost={handleDeletePost}
              blogPosts={blogPosts}
            />
          </>
        )}
        {view === 'subscription' && (
          <SubscriptionManagement
            subscriptionStatus={subscriptionStatus}
            onManageSubscription={redirectToCustomerPortal}
          />
        )}
      </main>
    </div>
  );
};

export default React.memo(Dashboard);
