import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore, auth } from '../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  arrayUnion,
  collection, 
  query, 
  where, 
  getDocs 
} from 'firebase/firestore';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Alert, AlertDescription } from './ui/alert';

function InviteProcessor() {
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const [inviterName, setInviterName] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    const fetchInviter = async () => {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('inviteCode', '==', inviteCode));
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const inviterData = snapshot.docs[0].data();
          setInviterName(inviterData.displayName || 'A friend');
        } else {
          setError('Invalid invite code. Please check the link and try again.');
        }
      } catch (error) {
        console.error('Error fetching inviter:', error);
        setError('Unable to process the invitation at this time. Please try again later or contact support.');
      }
    };
    fetchInviter();
  }, [inviteCode]);

  const handleSignInUp = async (e) => {
    e.preventDefault();
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      await handleInvitation(userCredential.user);
    } catch (error) {
      console.error('Error signing in/up:', error);
      setError(`Error ${isSignUp ? 'signing up' : 'signing in'}. ${error.message}`);
    }
  };

  const handleInvitation = async (user) => {
    try {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('inviteCode', '==', inviteCode));
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        throw new Error('Invalid invite code');
      }

      const inviterDoc = snapshot.docs[0];
      const inviterId = inviterDoc.id;

      const userDocRef = doc(firestore, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        // Create new user document
        await setDoc(userDocRef, {
          invitedBy: inviterId,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          createdAt: new Date(),
          friends: [inviterId],
        });
      } else {
        // Update existing user document
        await updateDoc(userDocRef, {
          invitedBy: inviterId,
          friends: arrayUnion(inviterId),
        });
      }

      // Update inviter's document to include the new user as a friend
      await updateDoc(doc(firestore, 'users', inviterId), {
        friends: arrayUnion(user.uid),
      });

      console.log('Friend relationship established:', { inviterId, newUserId: user.uid });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error processing invitation:', error);
      setError('Error processing invitation. Please try again or contact support.');
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleInvitation(result.user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error processing invitation. Please try again or contact support.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center p-6">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>{inviterName ? `${inviterName} has invited you to join GoalQuest!` : 'Join GoalQuest'}</CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {!error && (
            <>
              <form onSubmit={handleSignInUp} className="space-y-4 mb-4">
                <div>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="password">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <Button type="submit" className="w-full">
                  {isSignUp ? 'Sign Up' : 'Sign In'} with Email
                </Button>
              </form>
              <div className="text-center mb-4">
                <button
                  onClick={() => setIsSignUp(!isSignUp)}
                  className="text-sm text-primary hover:underline"
                >
                  {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
                </button>
              </div>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">
                    Or continue with
                  </span>
                </div>
              </div>
              <Button onClick={signInWithGoogle} className="w-full mt-4">
                Sign In with Google
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default InviteProcessor;